
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import GeneralUseSelect from "@/components/catalogs-select/GeneralUseSelect.vue";
import moment from "moment/moment";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}
export default {
  name: "TerrestrialVoyage",
  components: {
    CardBasicNew,
    TableBasicSelect,
    GeneralUseSelect
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const load = ref(false);
    const header = ref([
      {
        column: t("forwarder_job_traffic_type"),
        key: "titraffic_type",
      },
      { column: t("icarrier"), key: "icarrier" },
      { column: t("icommunications"), key: "icommunications" },
      { column: t("icontact"), key: "icontact" },
      {
        column: t("itrailer_registration_number"),
        key: "itrailer_registration_number",
      },
      {
        column: t("itruck_registration_number"),
        key: "itruck_registration_number",
      },
      { column: t("ideparturedate"), key: "ideparturedate" },
      { column: t("iarrivaldate"), key: "iarrivaldate" },
      { column: t("iclosingdate"), key: "iclosingdate" },
    ]);

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder terrestrial voyage: create"
        ) !== -1
      );
    });
    const canSeeDetails = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder terrestrial voyage: show"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder terrestrial voyage: update"
        ) !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "forwarder terrestrial voyage: delete"
        ) !== -1
      );
    });

    const showDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.value.user_preferences.view_type_items);
    const forwarder_job_traffic_type_id = ref([]);
    const carrier_id = ref([]);
    const communications_id = ref([]);
    const contact_id = ref([]);
    const description = ref("");
    const trailer_registration_number = ref("");
    const truck_registration_number = ref("");
    const departure_date = ref("");
    const arrival_date = ref("");
    const closing = ref("");
    const tableButtons = ref([]);
    const originalData = ref([]);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
      last_page: 1,
    });

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      ApiService.delete(
        `/api/forwarder/voyage/terrestrial/${deleteId.value}`
      ).then(({ data }) => {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        serverSearch();
      });
    };

    const updateData = (items: any) => {
      originalData.value = items;
      dataList.value = createFieldItems(items);
      filtered.value =
        viewType.value === "card" ? dataList.value : createTableItems(items);
      tableButtons.value = [
        {
          name: "Editar" as never,
          type: "EDIT" as never,
          color: "primary" as never,
        } as never,
      ];
    };

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/forwarder/voyage/terrestrial`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          forwarder_job_traffic_type_id: forwarder_job_traffic_type_id.value,
          carrier_id: carrier_id.value,
          communications_id: communications_id.value,
          contact_id: contact_id.value,
          description: description.value,
          trailer_registration_number: trailer_registration_number.value,
          truck_registration_number: truck_registration_number.value,
          departure_date_min: departure_date.value.length
            ? departure_date.value[0]
            : "",
          departure_date_max: departure_date.value.length
            ? departure_date.value[1]
            : "",
          arrival_date_min: arrival_date.value.length
            ? arrival_date.value[0]
            : "",
          arrival_date_max: arrival_date.value.length
            ? arrival_date.value[1]
            : "",
          closing_min: closing.value.length ? closing.value[0] : "",
          closing_max: closing.value.length ? closing.value[1] : "",
        },
      }).then(({ data }) => {
        updateData(data.forwarder_terrestrial_voyages);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        store.commit("setLoadingStatus", false);
      });
    };

    const createFieldItems = (data) => {
      const add_fields = data.map(function (element) {
        element.items = {
          id: element.id,
          icarrier: element.carrier.name,
          icommunications: element.communications.value,
          icontact: element.contact.name,
          itrailer_registration_number: element.trailer_registration_number,
          itruck_registration_number: element.truck_registration_number,
          ideparturedate: `${moment(element.departure_date).format(
            "DD/MM/YYYY"
          )}`,
          iarrivaldate: `${moment(element.arrival_date).format(
            "DD/MM/YYYY"
          )}`,
          iclosingdate: `${moment(element.closing).format(
            "DD/MM/YYYY"
          )}`,
        };
        return element;
      });
      return add_fields;
    };

    const createElement = () => {
      router.push({ name: "terrestrialVoyage" });
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          titraffic_type: element.forwarder_job_traffic_type.name,
          icarrier: element.carrier.name,
          icommunications: element.communications.value,
          icontact: element.contact.name,
          itrailer_registration_number: element.trailer_registration_number,
          itruck_registration_number: element.truck_registration_number,
          ideparturedate: `${moment(element.departure_date_min).format(
            "DD/MM/YYYY"
          )} - ${moment(element.departure_date_max).format("DD/MM/YYYY")}`,
          iarrivaldate: `${moment(element.arrival_date_min).format(
            "DD/MM/YYYY"
          )} - ${moment(element.arrival_date_max).format("DD/MM/YYYY")}`,
          iclosingdate: `${moment(element.closing_min).format(
            "DD/MM/YYYY"
          )} - ${moment(element.closing_max).format("DD/MM/YYYY")}`,
        });
      });
      return result;
    };

    watch(
      () => viewType.value,
      (first) => {
        if (first) {
          filtered.value =
            first === "card"
              ? createFieldItems(originalData.value)
              : createTableItems(originalData.value);
          pagination.value.total = filtered.value.length;
          pagination.value.last_page = Math.floor(filtered.value.length / 12);
        }
      }
    );

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push({ name: "offerEdit", params: { id: param1 } });
      } else if (param2 === "REMOVE") {
        deleteDialog(param3);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        {
          route: "/forwarder/terrestrial_voyages",
          label: "tterrestrialvoyage",
        },
      ]);
      serverSearch();
    });

    onUpdated(() => {
      //console.clear();
    });

    return {
      load,
      header,
      search,
      permissions,
      canCreate,
      canSeeDetails,
      canEdit,
      canDelete,
      showDelete,
      deleteId,
      dataList,
      filtered,
      forwarder_job_traffic_type_id,
      carrier_id,
      communications_id,
      contact_id,
      description,
      trailer_registration_number,
      truck_registration_number,
      departure_date,
      arrival_date,
      closing,
      viewType,
      tableButtons,
      originalData,
      pagination,
      formatDate,
      deleteDialog,
      deleteElement,
      currentPageChange,
      setItemsPerPage,
      onSearch,
      serverSearch,
      createElement,
      createTableItems,
      actionsButton,
      user,
    };
  },
};
